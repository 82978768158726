import React from 'react'
import Button from './atoms/Button'

const incentives = [
    {
        name: 'Quality Craftsmanship',
        imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-shipping-simple.svg',
        description: "Our skilled stucco artisans create stunning finishes that elevate your property's curb appeal and value.",
    },
    {
        name: 'Customized Solutions',
        imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-warranty-simple.svg',
        description: "Whether you prefer a traditional or contemporary style, we'll help you choose the perfect stucco finish to match your vision.",
    },
    {
        name: 'A+ Customer Service',
        imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-exchange-simple.svg',
        description:
            "Your satisfaction is our top priority. We'll work closely with you from start to finish, ensuring a seamless stucco experience.",
    },
    {
        name: 'Eco-Friendly Materials',
        imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-exchange-simple.svg',
        description:
            "Our commitment to the environment means we use only the highest quality, sustainable stucco products for your project.",
    },
    {
        name: 'Competitive Pricing',
        imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-exchange-simple.svg',
        description:
            "We pride ourselves on offering exceptional stucco services at competitive prices, without compromising on quality.",
    },
    {
        name: 'Financing Options',
        imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-exchange-simple.svg',
        description:
            "Our home improvement payment options help you create your dream home. Financing is subject to approved credit.",
    },
]
export const RenovationProcess = () => {
    return (
        <>
            <div className="from-gray-100 to-brand-100 bg-gradient-to-r py-4">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="py-16">
                        <div className="mx-auto max-w-xl lg:max-w-none">
                            <div className="text-center">
                                <h3 className="text-2xl md:text-3xl font-bold tracking-tight text-brand-600">
                                    Why Atlas Stucco?
                                </h3>
                            </div>
                            <div className="mx-auto mt-8 grid max-w-sm grid-cols-1 gap-y-10 gap-x-4 sm:max-w-none lg:grid-cols-3">
                                {incentives.map((incentive, i) => (
                                    <div key={incentive.name} className="text-center sm:flex sm:text-left lg:block ">
                                        {/* <div className="sm:flex-shrink-0">
                                        <div className="flow-root">
                                            <img className="mx-auto h-16 w-16" src={incentive.imageSrc} alt="" />
                                        </div>
                                    </div> */}
                                        {/* <div className="sm:flex-shrink-0">
                                        <div className="flow-root text-4xl font-display text-white">
                                            {i + 1}
                                        </div>
                                    </div> */}
                                        <div className="mt-3 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                                            <h4 className="text-lg font-semibold text-brand-700">{incentive.name}</h4>
                                            <p className="mt-2 text-sm text-gray-600">{incentive.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-brand-600 py-8'>
                <div className='max-w-7xl mx-auto  px-4 sm:px-6 lg:px-8 flex justify-between w-full items-center'>
                    <span className='block text-white text-2xl md:text-3xl font-semibold'>Start with a FREE Quote!</span>
                    <div><Button to='/contact' color='secondary'>Get Started</Button></div>
                </div>

            </div>
        </>
    )
}
